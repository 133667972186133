import React from "react"
import { ToastContainer } from "react-toastify"
import { GatsbyBrowser } from "gatsby"
import { TagManagerContextProvider } from "../contexts/tagManager"
import { FirebaseContextProvider } from "../contexts/firebase"
import { AuthUserContextProvider } from "../contexts/auth/user"

import "../global.css"

const wrapRootElement: GatsbyBrowser["wrapRootElement"] = ({ element }) => {
  return (
    <React.Fragment>
      <TagManagerContextProvider>
        <FirebaseContextProvider>
          <AuthUserContextProvider>{element}</AuthUserContextProvider>
        </FirebaseContextProvider>
      </TagManagerContextProvider>
      <ToastContainer />
    </React.Fragment>
  )
}

export default wrapRootElement
